import React from "react";
import {
  CubeTransparentIcon,
  HeartIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection2(props) {
  const items = [
    {
      title: "Passion",
      description:"We are focused on enabling compute infrastructure in every corner of the world.",
      icon: HeartIcon,
      iconColor: "text-red-500",
    },
    {
      title: "Commitment",
      description:
        "At Vega Labs we strongly believe that every user in the world needs access to the best resources and infrastructure in order to learn and grow.",
      icon: ArrowDownIcon,
      iconColor: "text-blue-500",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <div>
          {/* <CubeTransparentIcon className="text-blue-600 mb-5 inline-block w-16 h-16" /> */}
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="lg"
          />
        </div>
        <div className="flex space-x-2">
          <div>
            <img src={props.leftImage} alt="" className="rounded-lg" />
          </div>
          {/* <div>
            <img src={props.rightImage} alt="" className="rounded-lg" />
          </div> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {items.map((item, index) => (
            <div key={index}>
              <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
                <item.icon
                  className={
                    "inline-block w-5 h-5" +
                    (item.iconColor ? ` ${item.iconColor}` : "")
                  }
                />
                <span>{item.title}</span>
              </h3>
              <p className="leading-relaxed text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
