import React, { useState, useEffect } from 'react';
import Button from "./Button";
const GpuSelector = () => {
  const [selectedGpu, setSelectedGpu] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if submission was successful
  const [vm, setVm] = useState("please start vm")
  const id_token = sessionStorage.getItem('id_token'); // Assuming refreshToken is stored in localStorage
  const gpuOptions = [
    { id: 1, name: 'A100' },
    { id: 2, name: 'H100' },
    { id: 3, name: 'A10' },
    { id: 4, name: 'V100' },
    { id: 5, name: 'T40' },
  ];

    // Load VM link from localStorage on component mount
    useEffect(() => {
      const savedVm = sessionStorage.getItem('vm');
      if (savedVm) {
        setVm(savedVm);
        console.log("is vm present", savedVm);
      }
      else {
        handleSubmit();
      }
    }, []);
  

  const handleSubmit = async () => {
    const apiUrl = 'https://8vhtilwmpd.execute-api.us-east-1.amazonaws.com/v3/startvm'; // Replace with your actual API endpoint
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Use the refresh token in the request
        },
        body: JSON.stringify({ "token": id_token }),
      });
      if (!response.ok) throw new Error('Failed to submit GPU selection');
      const fetchGpuResponse = await fetch("https://8vhtilwmpd.execute-api.us-east-1.amazonaws.com/v3/fetchuserquota", {
        method: 'POST', // Or 'POST', if your backend requires it
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"token" : id_token})
      });
      // const data = await response.json();
      // console.log(data);
      setIsSubmitted(true); // Update state to reflect successful submission

      const fetchUserQuotaResponse = await fetchGpuResponse.json();
      const vmlink = 'http://' + fetchUserQuotaResponse.public_ip.Value + ':6080/vnc.html'
      setVm(vmlink);
      console.log("vmmmmmm",vmlink);
      sessionStorage.setItem('vm', vmlink);
      window.open("http://" + fetchUserQuotaResponse.public_ip.Value + ':6080/vnc.html', '_blank');

    } catch (error) {
      console.error('Error submitting GPU selection:', error);
      setIsSubmitted(false); // Ensure button is not disabled if submission fails
      // Handle error
    }
  };

  const handleStopVm = async () => {
    const apiUrl = 'https://8vhtilwmpd.execute-api.us-east-1.amazonaws.com/v3/stopvm'; // Replace with your actual API endpoint
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Use the refresh token in the request
        },
        body: JSON.stringify({ "token": id_token }),
      });
      if (!response.ok) throw new Error('Failed to stop VM');
      
      setIsSubmitted(false); // Update state to reflect successful submission
      setVm("please start vm to access it")
      localStorage.removeItem('vm');
    } catch (error) {
      console.error('Error submitting GPU selection:', error);
      setIsSubmitted(false); // Ensure button is not disabled if submission fails
      // Handle error
    }
  };

  return (
    <>
    <div className="flex justify-between items-center p-4 border-b border-gray-200">
      <select
        value={selectedGpu}
        onChange={(e) => setSelectedGpu(e.target.value)}
        className="dropdown base right normal inner"
        disabled={isSubmitted} // Disable dropdown if submission was successful
      >
        <option value="">Select a GPU</option>
        {gpuOptions.map((gpu) => (
          <option key={gpu.id} value={gpu.name}>
            {gpu.name}
          </option>
        ))}
      </select>
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
    
          <Button
            size="sm"
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitted}
          >
            Start VM
          </Button>
        </div>
        <div className="flex justify-between items-center p-2 border-b border-gray-200">
    
          <Button
            size="sm"
            variant="primary"
            onClick={handleStopVm}
          >
            Stop VM
          </Button>
        </div>
      {/* <button 
        onClick={handleSubmit} 
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        disabled={isSubmitted || !selectedGpu} // Disable button if submission was successful or no GPU is selected
      >
        
      </button> */}
    </div>
    <div className="prose prose-a:text-blue-600 max-w  p-4">
    <p className="inline-block p-2">Access VM here : </p>
    <a href={vm} target="_blank" className="inline-block p-2 hover:text-blue-700">
          {vm}
        </a>
    </div>
    </>
  );
};

export default GpuSelector;