import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import GpuSelector from "./GpuSelector";
import GPUQuotaTable from "./GpuQuotaRetriever";

function DashboardSection(props) {
  const auth = useAuth();

  console.log("session storgae  dasdhboard", sessionStorage);

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
            <GpuSelector/>
              {/* <DashboardItems /> */}
            </div>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <div className="p-4 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">

              <GPUQuotaTable/>
              </div>
     
          </div>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
