import React from "react";
import {
  AdjustmentsVerticalIcon,
  ChartPieIcon,
  GlobeAmericasIcon,
  BoltIcon,
  PuzzlePieceIcon,
  ServerStackIcon,
  BeakerIcon,
  CurrencyDollarIcon,
  UserPlusIcon,
  ClockIcon,
  ShieldCheckIcon
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Cost Effective",
      description:
      "We make sure to provide resources at the best possible prices for our users.",
      icon: CurrencyDollarIcon,
      iconColor: "emerald",
    },
    {
      title: "User Friendly",
      description:"We strive to build features that are extremely beginner friendly and use lesser jargon so they are extremely easy to use for students.",
      icon: UserPlusIcon,
      iconColor: "red",
    },

    {
      title: "24x7 Support",
      description:
        "We provide very strong support and are available round the clock for questions.",
      icon: ClockIcon,
      iconColor: "blue",
    },
    {
      title: "High Availability",
      description:
        "We work hard to keep our systems up and running all the time so the end user faces almost 0 downtime.",
      icon: ServerStackIcon,
      iconColor: "purple",
    },
    {
      title: "Highly Secure",
      description:
        "All our services run in highly secure environments behind highly secure firewalls.",
      icon: ShieldCheckIcon,
      iconColor: "orange",
    },
    // {
    //   title: "Standardized Lab Assignments",
    //   description: "We are also working on partnering with Universities to design labs assignments, etc. that help take the best education to Students across the world.",
    //   icon: BeakerIcon,
    //   iconColor: "pink",
    // },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
