import React, { useState, useEffect } from 'react';
import Button from "./Button";

const GPUQuotaTable = () => {
  // Initialize the GPU names and their corresponding quotas to an empty state initially
  const [gpuQuotas, setGpuQuotas] = useState([
    { name: 'A100', quota: '0 mins' },
    { name: 'H100', quota: '0 mins' },
    { name: 'A10', quota: '0 mins' },
    { name: 'V100', quota: '0 mins' },
    { name: 'T40', quota: '0 mins' },
  ]);
  
  const id_token = sessionStorage.getItem('id_token');


    const fetchGpuQuotas = async () => {
      const apiUrl = 'https://8vhtilwmpd.execute-api.us-east-1.amazonaws.com/v3/fetchuserquota';
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "token": id_token })
        });
        if (!response.ok) throw new Error('Failed to fetch GPU quotas');
        
        const data = await response.json();
        // Assuming data contains the structure as you've provided
        
        // Update quotas for each GPU based on the API response
        // setGpuQuotas([
        //   { name: 'A100', quota: data.available_large_gpu_quota.Value + ' mins' },
        //   { name: 'H100', quota: data.available_large_gpu_quota.Value + ' mins' },
        //   { name: 'A10', quota: data.available_small_gpu_quota.Value + ' mins' },
        //   { name: 'V100', quota: data.available_small_gpu_quota.Value + ' mins' },
        //   { name: 'T40', quota: data.available_small_gpu_quota.Value + ' mins' },
        // ]);
      } catch (error) {
        console.error('Error fetching GPU quotas:', error);
      }
    };

      // Fetch GPU quotas initially and then every minute
  useEffect(() => {
    fetchGpuQuotas(); // Initial fetch
    const intervalId = setInterval(fetchGpuQuotas, 60000); // Fetch every minute

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once on mount


  return (
    <div className="max-w-2xl mx-auto mt ">
      <div className="flex justify-between items-center p-4 border-b border-gray-400">
      <h2 className="text-xl font-semibold mb-4">GPU Quota Used</h2>
      <div>
      <Button
            size="sm"
            variant="primary"
            onClick={fetchGpuQuotas}
          >
            Update GPU Quota
          </Button>
          </div>
          </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto shadow-md">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 text-left">GPU</th>
              <th className="px-4 py-2 text-left">Quota Used</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {gpuQuotas.map((gpu, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="border px-4 py-2">{gpu.name}</td>
                <td className="border px-4 py-2">{gpu.quota || 'Loading...'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GPUQuotaTable;
